import React from 'react'
import SEO from "../components/seo"
import Layout from "../components/layout"
import styled from "styled-components";
import {LeftSection, RightSection, PageWrapper, Row, Span, Title, Title2} from '../components/UI'
import { breakpoints, apiURL, apiResponseStatus } from '../utils/helper'
import PageHeader from '../components/PageHeader'
import pattern from '../images/pattern-franchise.jpg'
import visual from '../images/visual-alkmaar.jpg'

const CopyBox = styled.div`
    justify-content: flex-start;
    margin-top: 100px;
    
    span {
        display: block;
        text-align: left;
        
        @media ${breakpoints.tablet} {
            text-align: center;
        }
    }
`


const CustomTitle = styled(Title)`
    color: ${props => props.theme.colors.white};
    
    b {
        color: ${props => props.theme.colors.red};    
    }
`

const BedanktPage = ({location}) => {
    return (
        <Layout location={location}>
            <SEO title="Bedankt | UpCarwash"
                 description="Bedankt voor je aankoop bij UPCarwash. We hopen je snel te mogen ontvangen!"/>
            <PageHeader image={visual} backgroundColor="#333333" textColor="#FFFFFF" imageWidth="520px" pattern={pattern}>
                <CustomTitle>
                    Bedankt voor je aankoop bij <b>UPCarwash</b>
                </CustomTitle>
            </PageHeader>

            <PageWrapper maxWidth="800px">
                <CopyBox>
                <Title2>
                    Bedankt voor je aankoop! Bereid je voor op een giezelig spektakel!<br/><br/>Tot dan!
                </Title2>
                </CopyBox>
            </PageWrapper>
        </Layout>
    )
}

export default BedanktPage
